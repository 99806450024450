::-webkit-scrollbar {
    width: 0px;
}

.wallet-adapter-button {
    background-color: #A70000 !important;
    font-family: "Strange dreams", sans-serif !important;
    font-weight: normal !important;
    padding: 0.5rem 0.5rem !important;
    min-height: 0px !important;
    height: fit-content !important;
    line-height: normal !important;
    font-size: 1rem !important;
}

.wallet-adapter-button:hover {
    background-color: #850202 !important;

}

.wallet-adapter-modal-list .wallet-adapter-button {
    background-color: transparent !important;
}

.button__red {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0.5rem 3rem !important;
    font-family: "Strange dreams", sans-serif !important;
    background: #A70000 !important;
    color: white !important;
    border-radius: 8px !important;
}

.button__red:hover {
    background-color: #850202 !important;
}

.button__yellow {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0.5rem 3rem !important;
    font-family: "Inter", sans-serif !important;
    background-color: #FFC43A !important;
    color: black !important;
    border-radius: 8px !important;
}

.button__yellow:hover {
    background-color: #f9b81f !important;
}

.item-container {
    background: #262626;
    border-radius: 12px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    overflow: hidden;
    align-content: center;
}

.item_container__img_container {
    width: 100%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.item-container img {
    border-radius: 10px;
    display: block;
    margin: auto;
    transition: all .2s ease-in;
    height: 100%;
}

.font-inter {
    font-family: 'Inter' !important;
}

.flex {
    display: flex;
}

.flex-column {
    flex-flow: column;
}

.flex-row {
    flex-flow: row;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-between {
    justify-content: space-between;
}

.flex-align-center {
    align-items: center;
}


.flex-wrap {
    flex-wrap: wrap;
}

.font-strange {
    font-family: "Strange dreams", sans-serif !important;
}

.heading-text {
    font-size: 2.5rem;
    color: white;
}

.utility__snack-bar {
    max-width: 90vw;
    font-family: 'Inter';
}

@media screen and (max-width: 769px) {
    .heading-text {
        font-size: 2rem;
    }
}


.centered-text {
    color: white;
    min-height: 50vh;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}




/* Modal  */
.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    animation-name: Modal-Wrapper;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 200;
}

@keyframes Modal-Wrapper {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}

.modal-container {
    display: flex;
    margin-top: 3rem;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 3rem;
    min-height: calc(100vh - 6rem);
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.modal {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1050;
    max-width: fit-content;
    background: #141414 !important;
    flex: 1 1;
    border-radius: 0px !important;
}

.modal-close-btn {
    padding: 2px 5px;
    display: inline-block;
    cursor: pointer;
    background: #1a1f2e;
    height: 1.2rem;
    border: none;
    border-radius: 50%;
    transition: all ease-in 0.2s;
    line-height: initial;
}

.modal-close-btn:hover {
    background-color: #3c4150;
}

.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    transition: opacity linear 150ms;
    animation-name: Modal-Wrapper;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    z-index: 0;
    overflow-x: hidden;
}

.utility__loader-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.utility__loader {
    width: 100px;
    height: 100px;
    max-width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
}

@keyframes load7 {
    100% {
        transform: rotatez(360deg);
    }
}

.utility__loader7::before {
    content: "";
    color: white;
    height: 50px;
    width: 50px;
    background: transparent;
    border-radius: 50%;
    border: 10px solid white;
    border-color: #ffffff #8e8e8e #535353 #313131;
    animation: load7 .6s infinite ease-in-out;
}

.utility__form .button,
.utility__form .message,
.customSelect,
.utility__form .select,
.utility__form .textarea,
.utility__form .text-input,
.utility__form .option-input+label,
.utility__form .checkbox-input+label,
.utility__form .label {
    padding: 0.75em 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    line-height: normal;
    border-radius: 0;
    border: none;
    background: none;
    display: block;
}

.utility__form .label {
    font-weight: bold;
    color: white;
    padding-top: 0;
    padding-left: 0;
    letter-spacing: 0.025em;
    font-size: 1.125em;
    line-height: 1.25;
    position: relative;
    z-index: 100;
}

.required .utility__form .label:after,
.utility__form .required .label:after {
    content: " *";
    color: #E8474C;
    font-weight: normal;
    font-size: 0.75em;
    vertical-align: top;
}

.customSelect,
.utility__form .select,
.utility__form .textarea,
.utility__form .text-input,
.utility__form .option-input+label,
.utility__form .checkbox-input+label {
    border: 1px solid #2c2c2c;
    font: inherit;
    line-height: normal;
    width: 100%;
    box-sizing: border-box;
    background: #222222;
    color: white;
    position: relative;
}

.customSelect:placeholder,
.utility__form .select:placeholder,
.utility__form .textarea:placeholder,
.utility__form .text-input:placeholder,
.utility__form .option-input+label:placeholder,
.utility__form .checkbox-input+label:placeholder {
    color: white;
}

.customSelect:-webkit-autofill,
.utility__form .select:-webkit-autofill,
.utility__form .textarea:-webkit-autofill,
.utility__form .text-input:-webkit-autofill,
.utility__form .option-input+label:-webkit-autofill,
.utility__form .checkbox-input+label:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #111111 inset;
    -webkit-text-fill-color: white;
    border-top-color: #111111;
    border-left-color: #111111;
    border-right-color: #111111;
}

.customSelect:not(:focus):not(:active).error,
.utility__form .select:not(:focus):not(:active).error,
.utility__form .textarea:not(:focus):not(:active).error,
.utility__form .text-input:not(:focus):not(:active).error,
.utility__form .option-input+label:not(:focus):not(:active).error,
.utility__form .checkbox-input+label:not(:focus):not(:active).error,
.error .customSelect:not(:focus):not(:active),
.error .utility__form .select:not(:focus):not(:active),
.utility__form .error .select:not(:focus):not(:active),
.error .utility__form .textarea:not(:focus):not(:active),
.utility__form .error .textarea:not(:focus):not(:active),
.error .utility__form .text-input:not(:focus):not(:active),
.utility__form .error .text-input:not(:focus):not(:active),
.error .utility__form .option-input+label:not(:focus):not(:active),
.utility__form .error .option-input+label:not(:focus):not(:active),
.error .utility__form .checkbox-input+label:not(:focus):not(:active),
.utility__form .error .checkbox-input+label:not(:focus):not(:active) {
    background-size: 8px 8px;
    background-image: linear-gradient(135deg, rgba(232, 71, 76, 0.5), rgba(232, 71, 76, 0.5) 25%, transparent 25%, transparent 50%, rgba(232, 71, 76, 0.5) 50%, rgba(232, 71, 76, 0.5) 75%, transparent 75%, transparent);
    background-repeat: repeat;
}

.utility__form:not(.has-magic-focus) .customSelect.customSelectFocus,
.utility__form:not(.has-magic-focus) .customSelect:active,
.utility__form:not(.has-magic-focus) .select:active,
.utility__form:not(.has-magic-focus) .textarea:active,
.utility__form:not(.has-magic-focus) .text-input:active,
.utility__form:not(.has-magic-focus) .option-input+label:active,
.utility__form:not(.has-magic-focus) .checkbox-input+label:active,
.utility__form:not(.has-magic-focus) .customSelect:focus,
.utility__form:not(.has-magic-focus) .select:focus,
.utility__form:not(.has-magic-focus) .textarea:focus,
.utility__form:not(.has-magic-focus) .text-input:focus,
.utility__form:not(.has-magic-focus) .option-input+label:focus,
.utility__form:not(.has-magic-focus) .checkbox-input+label:focus {
    background: #4E4E4E;
}

.utility__form .message {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    font-size: 0.625em;
    color: white;
}

.utility__form .option-input,
.utility__form .checkbox-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.utility__form .option-input+label,
.utility__form .checkbox-input+label {
    display: inline-block;
    width: auto;
    color: #4E4E4E;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.utility__form .option-input:focus+label,
.utility__form .checkbox-input:focus+label,
.utility__form .option-input:active+label,
.utility__form .checkbox-input:active+label {
    color: #4E4E4E;
}

.utility__form .option-input:checked+label,
.utility__form .checkbox-input:checked+label {
    color: white;
}

.utility__form .button {
    font: inherit;
    line-height: normal;
    cursor: pointer;
    background: #E8474C;
    color: white;
    font-weight: bold;
    width: auto;
    margin-left: auto;
    font-weight: bold;
    padding-left: 2em;
    padding-right: 2em;
}

.utility__form .green-button {
    background: #0b9837;
}


.utility__form .button:hover,
.utility__form .button:focus,
.utility__form .button:active {
    color: white;
    border-color: white;
}

.utility__form .button:active {
    position: relative;
    top: 1px;
    left: 1px;
}

body {
    padding: 2em;
}

.utility__form {
    box-sizing: border-box;
    padding: 1rem;
    width: 450px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 95vw;
}

.utility__form .field {
    width: 100%;
    margin: 0 0 1.5em 0;
}

@media screen and (min-width: 40em) {
    .utility__form .half {
        width: calc(50% - 1px);
    }
}

.utility__form .field.last {
    margin-left: auto;
}

.utility__form .textarea {
    max-width: 100%;
}

.utility__form .select {
    text-indent: 0.01px;
    text-overflow: "" !important;
}

.utility__form .select::-ms-expand {
    display: none;
}

.utility__form .checkboxes,
.utility__form .options {
    padding: 0;
    margin: 0;
    list-style-type: none;
    overflow: hidden;
}

.utility__form .checkbox,
.utility__form .option {
    float: left;
    margin: 1px;
}

.customSelect {
    pointer-events: none;
}

.customSelect:after {
    content: "";
    pointer-events: none;
    width: 0.5em;
    height: 0.5em;
    border-style: solid;
    border-color: white;
    border-width: 0 3px 3px 0;
    position: absolute;
    top: 50%;
    margin-top: -0.625em;
    right: 1em;
    transform-origin: 0 0;
    transform: rotate(45deg);
}

.customSelect.customSelectFocus:after {
    border-color: white;
}

.magic-focus {
    position: absolute;
    z-index: 0;
    width: 0;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.15);
    transition: top 0.2s, left 0.2s, width 0.2s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    will-change: top, left, width;
    transform-origin: 0 0;
}

.utility__form .array-input .add-element-btn {
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    padding: 0.470em 1em;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
}

.utility__form .array-input .add-element-btn:hover {
    background-color: rgb(49, 49, 49);
}
.utility__form .array-input .element {
    font: inherit;
    line-height: normal;
    box-sizing: border-box;
    background: #222222;
    color: white;
    position: relative;
    padding: 0 1em;
    appearance: none;
    outline: none;
    border-radius: 0;
    border: none;
    display: block;
}