#root {
    overflow-x: hidden;
}

@font-face {
    font-family: 'Strange dreams';
    src: url('https://uploads-ssl.webflow.com/640778606d0f506884db8e2e/6407f7e02b207404be129a7b_Strange%20Dreams.ttf') format('truetype'), url('https://uploads-ssl.webflow.com/640778606d0f506884db8e2e/6407f7e07186c65ea5f9a87e_Strange%20Dreams.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Strange dreams';
    src: url('https://uploads-ssl.webflow.com/640778606d0f506884db8e2e/6407f7e053298398d45c9d84_Strange%20Dreams%20Italic.ttf') format('truetype'), url('https://uploads-ssl.webflow.com/640778606d0f506884db8e2e/6407f7e064ee2e2492a87664_Strange%20Dreams%20Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');


body {
    margin: 0;
    font-family: "Strange dreams", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #070707;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}



html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: sans-serif;
}

body {
    margin: 0;
}


.container {
    padding: 0 60px;
    max-width: 1280px;
    margin: auto;
}

.container-padding {
    padding-left: 60px;
    padding-right: 60px;
    max-width: 1280px;
    margin: auto;
}

.container-left-padding {
    padding-left: 60px;
}

.container-right-padding {
    padding-right: 60px;
}

@media screen and (max-width:769px) {
    .container-padding {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .container-left-padding {
        padding: 1rem;
    }

    .container-right-padding {
        padding: 1rem;
    }

}

.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    height: 100px;
    padding: 36px 0px;
    flex-flow: row;
    align-items: center;
}

.navbar-logo {
    height: 3.5rem;
}

.nav-links {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    width: 100%;
    padding-left: 20px;
}

.nav-links-1-2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-link {
    padding: 12px 20px;
    color: white;
    cursor: pointer;
    transition: 0.1s all ease-in;
    text-decoration: none;
}

.nav-link:hover {
    color: #b90811;
}

.nav-link-selected {
    color: #b90811;
}

.header-container {
    overflow: hidden;
    margin-top: 2rem;
    background: linear-gradient(to bottom, #262626, #090909);
    min-height: 50vh;
    padding: 4rem 4rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.header__image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__image img {
    width: 400px;
    max-width: 80vw;
}

.header__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 400px;
    max-width: 80vw;
    text-align: center;
    justify-content: center;
    font-size: 3rem;
    gap: 1rem;
    color: white;
}

@media screen and (max-width:769px) {
    .header-container {
        gap: 0.5rem;
    }

    .header__content {
        width: 300px;
        font-size: 2rem;
    }

    .header__image img {
        width: 300px;
    }
}

.home__category__items__container {
    display: flex;
    flex-flow: row wrap;
    gap: 1.5rem;
    padding-bottom: 2rem;
}

@media screen and (max-width:769px) {
    .home__category__label {
        text-align: center;
    }

    .home__category__items__container {
        justify-content: center;
    }
}

.home__category__item {
    max-width: 40vw;
    text-align: center;
    gap: 1rem;
    color: white;
    padding-bottom: 0.5rem;
}

.home__category__label {
    color: white;
    font-size: 1.5rem;
}

.home__category__item img {
    border-radius: 10px;
    display: block;
    margin: auto;
    transition: all .2s ease-in;
    height: 100%;
}

.home__categories__container {
    display: flex;
    flex-flow: column;
    gap: 3rem;
    margin-top: 1rem;
}

.home__category__item__content-text {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
}

.catalog__category__item:hover {
    transition: all ease-in 0.1s;
    background: #202020;
    cursor: pointer;
}

/* Item  */
.item__wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.item__image {
    /* width: 100%;
    border-radius: 15px;
    aspect-ratio: 1 / 1;
    background: #262626;
    overflow: hidden; */

    width: 100%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.item__image img {
    border-radius: 10px;
    display: block;
    margin: auto;
    transition: all .2s ease-in;
    width: 100%;

}

.item__info__container {
    width: 40%;
    color: white;
    gap: 2rem;
    padding: 2rem 0px 2rem 2.5rem;
}

@media screen and (max-width: 769px) {
    .item__info__container {
        width: 100%;
        padding: 2rem 1rem;
    }
}

.item__info__red-options {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
}

.item__info__red-option input {
    display: none;
}

.item__info__red-option label {
    background: #262626;
    cursor: pointer;
    min-width: 30px;
    color: white;
    padding: 0.5rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item__info__red-option input:checked+label {
    background: #b90811;
}

.item__info-input__quantity {
    background: #262626;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    gap: 1rem;
    padding: 0.2rem 0.5rem;
}

.cart__checkout__item__quantity {
    position: absolute;
    top: -0.4rem;
    right: -0.4rem;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    aspect-ratio: 1 / 1;
    background: #A70000;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}